.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 12px; /* Increased gap between items for better spacing */
    color: #5e5e5e;
    font-size: 16px; /* Increased font size for better readability */
    font-weight: 500;
    margin: 20px 12%; /* Adjusted margin for better balance */
    padding: 10px 0; /* Increased padding for more vertical space */
}

.breadcrumbs img {
    width: 15px; /* Slightly larger arrows for better visibility */
    height: auto;
}

@media (max-width: 1024px) {
    .breadcrumbs {
        font-size: 14px; /* Adjusted for medium screens */
        margin: 20px 10%; /* Slightly reduced margin */
        gap: 10px; /* Reduced gap */
    }
}

@media (max-width: 800px) {
    .breadcrumbs {
        font-size: 12px; /* Smaller font size for smaller screens */
        margin: 20px 5%; /* Further reduced margin for small screens */
        gap: 8px; /* Smallest gap */
    }
}
