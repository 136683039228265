/* Importing Google Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.hero {
    min-height: 100vh;
    background: linear-gradient(180deg, #621C38, #ffffff 70%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 9%;
    font-family: 'Roboto', sans-serif;    
}

.hero-left {
    line-height: 1.1;
    max-width: 600px;
}

.hero-left h2 {
    color: #ffffff;
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: 700;
   
}

.hero-left p {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
    margin: 10px 0; /* Reduced margin for closer text */
}

.hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 15px; /* Reduced gap for closer alignment */
    margin-bottom: 20px;
}

.hero-hand-icon img {
    width: 50px;
}

.hero-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d6b600;
    color: white;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background 0.3s ease;
}

.hero-latest-btn:hover {
    background: #c0a000;
}

.hero-latest-btn img {
    width: 18px;
}

.hero-right img {
    width: 500px;
}

@media (max-width: 1200px) {
    .hero {
        padding: 10px 6%;
    }

    .hero-left p {
        font-size: 35px;
    }

    .hero-right img {
        width: 400px;
    }
}

@media (max-width: 900px) {
    .hero-left p {
        font-size: 30px;
    }

    .hero-right img {
        width: 300px;
    }
}

@media (max-width: 700px) {
    .hero {
        padding: 0 10%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .hero-left {
        max-width: 100%;
    }

    .hero-left p {
        font-size: 30px;
        text-align: center;
    }

    .hero-right {
        display: block; /* Make the image visible on mobile */
        margin-top: 20px; /* Add some space above the image */
    }

    .hero-left h2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .hero-latest-btn {
        margin-top: 30px;
        width: 100%;
        margin-bottom: auto;
    }

    .hero-right img {
        width: 300px; /* Adjust image width for mobile view */
        margin-top: 20px; /* Add some space above the image */
        margin-bottom: 30px;
    }
}
