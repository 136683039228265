.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px -2px black;
 
    position: relative;
}

.nav-left, .nav-right {
    display: flex;
    align-items: center;
}

.nav-left {
    flex: 1;
    justify-content: flex-start;
}

.nav-right {
    flex: 1;
    justify-content: flex-end;
}

.nav-center {
    flex: 0.5;
    display: flex;
    justify-content: center;
}

.nav-logo {
    display: flex;
    align-items: center;
    gap: 60px;
}

.nav-logo img {
    width: 200px;
}

.nav-menu-left{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px; /* Adjusted spacing to bring items closer */
    color: #626262;
    font-size: 16px;
    font-weight: 650;
    padding-left: 69%;
}

.nav-menu-right {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px; /* Adjusted spacing to bring items closer */
    color: #626262;
    font-size: 16px;
    font-weight: 650;
    flex: 1;
    
}

.nav-menu-left li, .nav-menu-right li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}

.nav-menu-left hr, .nav-menu-right hr {
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}

.nav-icons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto; /* Ensures icons are pushed to the far right */
    padding-right: 10%;
}

.nav-login img {
    width: 55px;
    height: 45px;
    cursor: pointer;
}

.nav-cart {
    position: relative;
}

.nav-cart img {
    width: 35px;
}

.nav-cart-count {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 14px;
    background: red;
    color: white;
}

.nav-dropdown {
    display: none; /* Hidden by default */
}

.nav-menu-mobile {
    display: none;
    flex-direction: column;
    list-style: none;
    gap: 15px;
    position: absolute;
    background-color: white;
    top: 100%; /* Start below the navbar */
    left: 0;
    width: 100%;
    padding: 15px;
    box-shadow: 0 1px 3px -2px black;
    transition: max-height 0.3s ease-out, padding 0.6s ease-out; /* Added transition for smooth drop-down */
    max-height: 0;
    overflow: hidden;
    z-index: 1000; /* Ensure the dropdown is on top */
}

.nav-menu-mobile.nav-menu-visible {
    display: flex;
    max-height: 500px; /* Increased max-height to avoid cutting off */
}

.nav-menu-mobile li {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    border-radius: 8px;
    margin: 5px 0;
    font-weight: bold;
}

@media(max-width:900px) {
    .nav {
        padding: 0px 20px;
    }
    .nav-menu-left, .nav-menu-right {
        display: none;
    }
    .nav-icons {
        gap: 10px;
    }
    .nav-dropdown {
        display: block; /* Visible on mobile */
        width: 80px; /* Made the image bigger */
        height: 80px; /* Made the image bigger */
        cursor: pointer;
        position: relative; /* Added to align with other items */
        top: 5px; /* Align with other nav items */
        transition: transform 0.3s ease; /* Added transition for smooth animation */
        transform-origin: center; /* Ensure the rotation happens in place */
    }
    .nav-dropdown.open {
        transform: rotate(90deg);
    
    }
}

@media(max-width:500px) {
    .nav {
        padding: 0px 15px;
    }
    .nav-logo img {
        width: 150px;
    }
    .nav-right {
        transform: scale(0.8);
    }
    .nav-right {
        gap: 10px;
    }
}