/* AboutUs.css */

/* Ensures text is readable and modern-looking */
body {
    font-family: 'Arial', sans-serif; /* Modern and readable font */
}

/* Styling for the About Us container */
.container {
    text-align: center; /* Center-aligns all text within the container */
    padding: 50px 20px; /* Adds vertical and horizontal padding */
    margin: auto; /* Centers the container horizontally if it has a set width */
    max-width: 800px; /* Maximum width of the container to maintain readability */
    background-color: #ffffff; /* Optional: background color for the container */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds subtle shadow for depth */
    border-radius: 10px; /* Optional: rounds the corners */
}

/* Headings styled to stand out */
h1 {
    font-size: 2.5rem; /* Larger font size for the main heading */
    color: #333; /* Dark grey color for better contrast */
    margin-bottom: 30px; /* Space below the heading */
}

/* Paragraphs with comfortable reading line-height */
p {
    font-size: 1.2rem; /* Larger font size for readability */
    color: #666; /* Lighter grey for the paragraph text */
    line-height: 1.6; /* Line height for better readability */
    margin-bottom: 20px; /* Space between paragraphs */
}

/* Emphasizes important text */
.font-weight-bold {
    font-weight: bold;
}

/* Centers certain text for emphasis */
.text-center {
    text-align: center;
}
