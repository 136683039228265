.shopcategory {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #f9f9f9;
  }
  
  .shopcategory-banner {
    width: 100%; /* Ensures the image takes up the full container width */
    height: 250px; /* Set a fixed height to crop the image */
    object-fit: cover; /* Ensures the image covers the full container without stretching */
    object-position: center; /* Adjusts the focus of the image to the center */
    margin-bottom: 20px; /* Keeps the original bottom margin */
}

  
  .shopcategory-description {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 80%;
  }
  
  .shopcategory-description h1 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .shopcategory-description p {
    color: #666;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .shopcategory-description form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  
  .shopcategory-description form label {
    font-size: 14px;
    color: #444;
  }
  
  .shopcategory-description form input,
  .shopcategory-description form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .shopcategory-description form button {
    background-color: #d6b600;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .shopcategory-description form button:hover {
    background-color: #4a54e1;
  }
  
  .shopcategory-indexSort {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
  }
  
  .shopcategory-sort {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .shopcategory-products {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 80%;
  }
  
  .shopcategory-loadmore {
    margin: 20px 0;
    font-size: 16px;
  }
  