.new-collections {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 100px;
}

.new-collections h1 {
    color: #171717;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.new-collections hr {
    width: 150px;
    height: 4px;
    border-radius: 10px;
    background: #252525;
    margin: 0 auto; /* Center the horizontal line */
}

.collections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 60px auto;
    gap: 30px;
    max-width: 1280px;
}

/* Media query for mobile view */
@media (max-width: 600px) {
    .new-collections h1 {
        font-size: 30px;
    }
    .collections {
        flex-direction: column;
        align-items: center;
    }
    .collections .item {
        width: 100%; /* Ensure items take full width on mobile */
        max-width: 300px; /* Adjust max-width as needed */
    }
}
