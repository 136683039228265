.productdisplay-container {
    padding: 0 10%;
}

.productdisplay-content {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

.productdisplay-left {
    display: flex;
    gap: 18px;
    margin-left: 10%;
}

.productdisplay-img-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center; /* Centers images if they have different widths */
}

.productdisplay-img-list img {
    width: auto;  /* Maintains width according to natural aspect ratio */
    height: 100px; /* Fixed height to ensure uniformity in the list */
    object-fit: contain; /* Ensures the image fits well within the given dimensions */
    border: 1px solid #ccc; /* Adds a subtle border for visual consistency */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds depth with a shadow */
}

.productdisplay-main-img {
    max-height: 400px; /* Limits the maximum height */
    max-width: 400px; /* Limits the maximum width */
    width: auto;  /* Adjusts the width automatically to maintain aspect ratio */
    height: auto; /* Adjusts the height automatically to maintain aspect ratio */
    object-fit: contain; /* Ensures the image is scaled properly within the dimensions */
    border: 1px solid #ccc; /* Optional: adds a subtle border for better definition */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds slight shadow for depth */
}

.productdisplay-right {
    display: flex;
    flex-direction: column;
    margin-right: 10%;
}

.productdisplay-right h1 {
    color: #3d3d3d;
    font-size: 30px;
    font-weight: 700;
}

.productdisplay-right-stars {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
    color: #1c1c1c;
    font-size: 14px;
}

.productdisplay-right-prices {
    display: flex;
    margin: 25px 0;
    gap: 20px;
    font-size: 22px;
    font-weight: 700;
}

.productdisplay-right-price-old {
    color: #818181;
    text-decoration: line-through;
}

.productdisplay-right-price-new {
    color: #ff4141;
}

.productdisplay-right-description {
    margin: 25px 0;
    color: #656565;
    font-size: 18px;
    font-weight: 600;
    margin-right: 20%;
}

.productdisplay-right button {
    padding: 20px 40px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: #FF4141;
    margin-bottom: 25px;
    border: none;
    outline: none;
    cursor: pointer;
}

.productdisplay-right-category {
    margin-top: 10px;
}

.productdisplay-right-category span {
    font-weight: 600;
}

/* ----- media queries ------- */

/* Media Queries for responsiveness */

@media (max-width: 1024px) {
    .productdisplay-container {
        padding: 0 5%;
    }

    .productdisplay-content {
        flex-direction: column;
        align-items: center;
        margin-right: 10%;
        margin-left: 10%;
    }

    .productdisplay-main-img {
        max-width: 90%;  /* Adjust width to better fit the screen */
        height: auto;  /* Adjust height automatically to maintain aspect ratio */
    }

    .productdisplay-left,
    .productdisplay-right {
        margin-left: 0;
        margin-right: 0;
        align-items: center;
    }

    .productdisplay-right h1 {
        font-size: 34px;
        text-align: left;
    }

    .productdisplay-right-prices {
        flex-direction: column;
        align-items: center;
        margin: 15px 0;
        font-size: 18px;
    }
}

@media (max-width: 800px) {
    .productdisplay-container {
        padding: 0 3%;
    }

    .productdisplay-img-list img {
        height: 70px;  /* Adjust image sizes in list for smaller screens */
    }

    .productdisplay-right h1,
    .productdisplay-right-prices,
    .productdisplay-right-description,
    .productdisplay-right button {
        font-size: 16px;  /* Uniform font size for better readability */
        
    }

    .productdisplay-right-description {
        font-size: 14px;  /* Slightly smaller font size for descriptions */
        margin-right: 0;  /* Reset margin */
    }

    .productdisplay-right button {
        padding: 10px 18px;  /* Smaller padding for buttons */
        width: auto;  /* Adjust button width to content */
    }
}

@media (max-width: 500px) {
    .productdisplay-container {
        padding: 0 1%;  /* Even less padding to maximize screen usage */
    }

    .productdisplay-main-img {
        max-width: 100%;  /* Full width for main image to utilize space */
    }

    .productdisplay-img-list img {
        height: 60px;  /* Even smaller thumbnails for very small screens */
    }

    .productdisplay-right h1 {
        font-size: 18px;  /* Keep readable font size */
    }

    .productdisplay-right-prices,
    .productdisplay-right-description,
    .productdisplay-right button {
        font-size: 14px;  /* Uniform smaller font size for consistency */
    }

    .productdisplay-right-description {
        text-align: center;  /* Center text for better alignment */
    }

    .productdisplay-right button {
        padding: 8px 16px;  /* Adjust padding for smaller screens */
    }
}
